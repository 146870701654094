<template>
  <div
    :data-active="active"
    @dragenter.prevent="setActive(true)"
    @dragover.prevent="setActive(true)"
    @dragleave.prevent="setActive(false)"
    @drop.prevent="onDrop"
  >
    <template v-if="!props.showList && files.length">
      <DropZoneImage
        :class="multiple ? (!allowSelection ? 'col-3' : 'col-4') : 'w-100'"
        :allowSelection="props.allowSelection"
        :uploadable="file"
        :key="file.id"
        v-for="(file, index) in files"
        :selectable="multiple"
        @toggleSelect="(isSelected) => toggleFileSelect(index, isSelected)"
        @remove="() => removeFile(files[index])"
      />
    </template>
    <div :class="multiple ? (!allowSelection ? 'col-3' : 'col-4') : 'w-100'" v-show="props.mediaCount > files.length">
      <label class="dropzone" :for="randomInputId">
        <div class="dz-message text-center">
          <IconCloudUpload :size="30" :stroke-width="1.2" />
          <h6 class="mt-2 text-uppercase fw-bold">{{ active ? props.dropLabel : props.label }}</h6>
        </div>
        <input
          class="d-none"
          type="file"
          :id="randomInputId"
          :multiple="multiple"
          @change="onInputChange"
          tabindex="-1"
          ref="fileInput"
        />
      </label>
    </div>
    <div class="dz-files gap-2" v-if="files.length && props.showList">
      <div class="d-flex justify-content-between align-items-center" v-if="multiple">
        <p class="m-0">{{ uploadedCount }} of {{ files.length }} uploaded</p>
      </div>
      <DropZoneItem :uploadable="file" :key="file.id" v-for="file in files" @remove="removeFile(file)" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { IconCloudUpload } from '@tabler/icons-vue';
import type { ImageObject } from '@/types/types';
import { onMounted, onUnmounted, ref, computed, watch } from 'vue';
import DropZoneItem from './DropZoneItem.vue';
import { type UploadableFile, createId, createFile, startProgress } from './utils';

import { requestMultipart } from '@/api/client';
import translator from '@/locale/translator';
import DropZoneImage from './DropZoneImage.vue';

const fileInput = ref<HTMLInputElement>();
const randomInputId = Math.random().toString();
const emit = defineEmits(['update:modelValue']);
const props = withDefaults(
  defineProps<{
    selectable?: boolean;
    label?: string;
    dropLabel?: string;
    modelValue: ImageObject[];
    mediaCount?: number;
    showList?: boolean;
    allowSelection?: boolean;
  }>(),
  {
    mediaCount: 50,
    showList: false,
    label: translator.get('dropzone.drop_browse'),
    dropLabel: translator.get('dropzone.drop_msg'),
    allowSelection: true,
  }
);

defineExpose({ openInput: () => fileInput.value?.click() });

const files = ref<UploadableFile[]>([]);
const uploadedCount = computed(() => files.value.filter((a: UploadableFile) => true === a.status).length || 0);
const multiple = computed(() => props.mediaCount > 1);

// When we have initial data - transform it to uploadableFile with 100%
watch(
  () => props.modelValue,
  (newVal, oldVal) => {
    if (!files.value.length && !oldVal?.length && newVal.length) {
      newVal.forEach((v) => {
        files.value.push(createFile(v, true));
      });
    }
  },
  { immediate: true }
);

function toggleFileSelect(index: number, isSelected: boolean) {
  files.value[index].file.selected = isSelected;
}

async function startUpload(file: UploadableFile) {
  if (!file.blob) return;
  startProgress(file);
  const formData = new FormData();
  formData.append('image', file.blob);

  try {
    const res: { data: ImageObject } = await requestMultipart(
      'POST',
      `/v1/account/media/images/upload`,
      { class: 0 },
      formData
    );
    // Preserve the file name, but fill in the rest of the ImageObject
    file.file = { ...res.data, description: file.file.description, selected: true };
    // Set progressbar percentage to uploaded
    clearInterval(file.interval);
    file.percentage = 100;
    file.status = true;
    // Update the parent about all uploaded files
    if (!multiple.value) emit('update:modelValue', [file.file]);
    else emit('update:modelValue', [...props.modelValue, file.file]);
  } catch (error) {
    if (!props.showList) {
      // If we show images, remove the failed file
      return removeFile(file);
    }
    file.status = false;
    file.percentage = 100;
    console.error(error);
  }
}

function addFiles(newFiles: FileList | []) {
  // This case will never happen, but it's good to check
  if (!newFiles.length) return;
  // Extract all non-failed existing file ids
  const existing = files.value.filter((a) => a.status !== false).map((a) => a.id);

  for (const f of [...newFiles] as File[]) {
    // If the file provided is not an image or is duplicate, skip it
    if (!f.type.includes('image') || existing.includes(createId(f.name, f.size))) return;
    // Create an uploadable object, and mimic full ImageObject
    const uploadable = createFile({ description: f.name, size: f.size, blob: f } as ImageObject);

    let filesLength = 1;
    if (multiple.value) filesLength = files.value.push(uploadable);
    else files.value = [uploadable];

    // Initiate progress bar movement and upload the file
    startUpload(files.value[filesLength - 1]);
    // If NOT multiple, then stop after the first file
    if (!multiple.value || files.value.length >= props.mediaCount) break;
  }
}

function removeFile(file: UploadableFile) {
  const index = files.value.indexOf(file);

  if (index > -1) files.value.splice(index, 1);
  // Update the parent about all remaining files
  emit(
    'update:modelValue',
    files.value.map((f: UploadableFile) => f.file)
  );
}

/* ------------------- Create `active` state and manage it ------------------ */
const active = ref(false);
// The timeout prevents most of the flickering
// see https://www.smashingmagazine.com/2022/03/drag-drop-file-uploader-vuejs-3/#active-state
let inActiveTimeout: number;
function setActive(val: boolean) {
  active.value = val;
  if (val) return clearTimeout(inActiveTimeout);
  inActiveTimeout = setTimeout(() => {
    active.value = false;
  }, 30);
}

/* --------------------- FILE INPUT BROWSE OR DRAG-DROP --------------------- */
function onInputChange(e: any) {
  if (!e?.target) return;
  addFiles(e.target.files);
  e.target.value = null;
}
function onDrop(e: DragEvent) {
  setActive(false);
  addFiles(e.dataTransfer?.files || []);
}

/* ------------- PREVENT DEFAULT BROWSER BEHAVIOR ON DRAG-N-DROP ------------ */
const events = ['dragenter', 'dragover', 'dragleave', 'drop'];
function preventDefaults(e: Event) {
  e.preventDefault();
}
onMounted(() => {
  events.forEach((eventName) => {
    document.body.addEventListener(eventName, preventDefaults);
  });
});

onUnmounted(() => {
  events.forEach((eventName) => {
    document.body.removeEventListener(eventName, preventDefaults);
  });
});

// /v1/media/images/upload
</script>
<style scoped>
.dropzone {
  border: 2px dashed var(--tblr-border-color);
  color: var(--tblr-muted);
  padding: 1rem;
  min-height: 100px;
  border-radius: var(--tblr-border-radius);
  cursor: pointer;
  display: block;
}
.dropzone .dz-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dz-files {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}
</style>
